import './MainSection.scss'

import { HashLink } from 'react-router-hash-link'

import Trevor from '../../images/Trevor.png'
import Trevor2 from '../../images/Trevor@2x.png'
import Cars from '../../images/Cars.png'
import Cars2 from '../../images/Cars@2x.png'

import Servers from '../Servers/Servers'

export const MainSection = () => {
  return (
    <div className="mainsection">
      <div className="mainsection__inner container">
        {/*Тревор*/}
        <img src={Cars} srcSet={`${Cars2} 2x`} alt={'...'} className={'cars'} />
        <img src={Trevor} srcSet={`${Trevor2} 2x`} alt={'...'} className={'trevor'} />

        {/*Заголовок*/}
        <div className="mainsection__inner--left">
          <div className="maintitle">
            <p className="maintitle__top">
              <span>—</span> Добейся успеха, играя на сервере
            </p>
            <h1 className="maintitle__head">
              <span>SMOTRA</span>rage
            </h1>
            <p className="maintitle__bottom">Сервер на котором играет и живёт Эрик Давидыч</p>

            <HashLink to={'/#start'} smooth className="maintitle__btn">
              Начать играть
            </HashLink>
          </div>

          <img src={Cars} srcSet={`${Cars2} 2x`} alt={'...'} className={'cars-min'} />
          <img src={Trevor} srcSet={`${Trevor2} 2x`} alt={'...'} className={'trevor-min'} />
        </div>

        {/*Список серверов*/}
        <div className="mainsection__inner--right">
          <Servers />
        </div>
      </div>
    </div>
  )
}

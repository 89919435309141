import axios from 'axios'

export const validateUsername = ({ username }) => {
  return axios.get(`https://smotrarage.ru/api/checklog.php?login=${username}`)
}

export const getEnotData = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/enotPremium.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}

export const getEnot3Data = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/enotPremium3.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}

export const getPGSData = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/pgsPremium.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}
export const getPayPalichData = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/paypalichPremium.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}

export const getGMPaysData = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/gmpaysPremium.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}

export const getPSONData = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/paySelPremium.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}

export const getFreekassaData = ({ username, server, amount, id }) => {
  return axios.get(
    `https://smotrarage.ru/api/freekassaPremium.php?srv=${server}&login=${username}&summa=${amount}&premium=${id}`
  )
}

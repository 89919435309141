export const selectStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
    background: 'none',
    fontSize: '14px',
    outline: 'none',
    border: 0,
    boxShadow: 'none'
  }),

  menu: (base, state) => ({
    ...base,
    backgroundColor: '#2b2e39',
    marginTop: 0,
    marginLeft: -10
  }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? '#fff' : '#ccc',
    fontSize: 14,
    fontWeight: state.isSelected ? '600' : '400',
    backgroundColor: state.isSelected ? '#333645' : 'transparent',
    '&:hover': {
      backgroundColor: state.isFocused ? '#272a34' : 'grtransparenteen'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 'none',
    color: 'red'
  }),
  placeholder: (base) => ({
    ...base,
    margin: '0',
    color: '#757575'
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),

  singleValue: (base) => ({
    ...base,
    color: '#fff',
    fontWeight: 600
  })
}

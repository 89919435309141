import { Link } from 'react-router-dom'
import FooterLogo from '../../images/FooterLogo.png'
import FooterLogo2 from '../../images/FooterLogo@2x.png'
import './Footer.scss'

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__left">
        <img src={FooterLogo} srcSet={`${FooterLogo2} 2x`} alt={'...'} />

        <p className="footer__company">
          INTERNATIONAL BUSINESS SYSTEMS S.R.L. <br />
          Reg. number: 3-102-693823 <br />
          Beneficiary’s address: SAN JOSE-SANTA ANA,THREE HUNDRED AND FIFTY METERS OF THE RESTAURANT
          CEVICHE DEL REY, COSTA-RICA <br />
        </p>
      </div>
      <div className="footer__right">
        <a href="https://smotramta.ru/" target="_blank" rel="noreferrer">
          SMOTRAmta
        </a>
        <a href="https://vk.com/topic-178026244_40148208" target="_blank" rel="noreferrer">
          Помощь
        </a>
        <a href="https://vk.com/topic-178026244_41058579" target="_blank" rel="noreferrer">
          Правила сервера
        </a>
        <Link to={'/policy'}>Политика конфиденциальности</Link>
        <a href="https://smotrarage.ru/cdn/agreement.pdf" target="_blank" rel="noreferrer">
          Публичная оферта
        </a>
      </div>
    </div>
  )
}

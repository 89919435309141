import { combine, createEffect, createEvent, createStore, sample } from 'effector'
import { useStore } from 'effector-react'
import {
  getEnot3Data,
  getEnotData,
  getFreekassaData,
  getGMPaysData,
  getPGSData,
  getPSONData,
  getPayPalichData
} from '../api'
import { $premiumAmount, $premiumId, $server, $username } from './pay'

/** events */
export const getPaymentData = createEvent()

/** effects */
const getEnotDataFx = createEffect(({ username, server, id, amount }) => {
  return getEnotData({ username, server, id, amount })
})

const getEnot3DataFx = createEffect(({ username, server, id, amount }) => {
  return getEnot3Data({ username, server, id, amount })
})

const getPGSDataFx = createEffect(({ username, server, id, amount }) => {
  return getPGSData({ username, server, id, amount })
})

const getPayPalichDataFx = createEffect(({ username, server, id, amount }) => {
  return getPayPalichData({ username, server, id, amount })
})

const getFreekassaDataFx = createEffect(({ username, server, id, amount }) => {
  return getFreekassaData({ username, server, id, amount })
})

const getGMPaysDataFx = createEffect(({ username, server, id, amount }) => {
  return getGMPaysData({ username, server, id, amount })
})

const getPSONDataFx = createEffect(({ username, server, id, amount }) => {
  return getPSONData({ username, server, id, amount })
})

/** stores */

const $paymentUsername = combine($username, (username) => username)

const $serverId = $server.map((server) => server.value)
const $premAmount = $premiumAmount.map((amount) => amount)
const $premId = $premiumId.map((amount) => amount)

/** ENOT */
const $enotData = createStore(null).on(getEnotDataFx.doneData, (_, res) => res.data)
const $enotDataFail = createStore(false)
  .on(getEnotDataFx.failData, () => true)
  .reset(getEnotDataFx)
const $enotDataLoading = getEnotDataFx.pending

/** Enot3 */
const $enot3Data = createStore(null).on(getEnot3DataFx.doneData, (_, res) => res.data)
const $enot3DataFail = createStore(false)
  .on(getEnot3DataFx.failData, () => true)
  .reset(getEnot3DataFx)
const $enot3DataLoading = getEnot3DataFx.pending

/** PGS */
const $pgsData = createStore(null).on(getPGSDataFx.doneData, (_, res) => res.data)
const $pgsDataFail = createStore(false)
  .on(getPGSDataFx.failData, () => true)
  .reset(getPGSDataFx)
const $pgsDataLoading = getPGSDataFx.pending

/** PayPalich */
const $payPalichData = createStore(null).on(getPayPalichDataFx.doneData, (_, res) => res.data)
const $payPalichDataFail = createStore(false)
  .on(getPayPalichDataFx.failData, () => true)
  .reset(getPayPalichDataFx)
const $payPalichDataLoading = getPayPalichDataFx.pending

/** GMPays */
const $gmPaysData = createStore(null).on(getGMPaysDataFx.doneData, (_, res) => res.data)
const $gmPaysDataFail = createStore(false)
  .on(getGMPaysDataFx.failData, () => true)
  .reset(getGMPaysDataFx)
const $gmPaysDataLoading = getGMPaysDataFx.pending

/** PSON */
const $psonData = createStore(null).on(getPSONDataFx.doneData, (_, res) => res.data)
const $psonDataFail = createStore(false)
  .on(getPSONDataFx.failData, () => true)
  .reset(getPSONDataFx)
const $psonDataLoading = getPSONDataFx.pending

/** Freekassa */
const $freekassaData = createStore(null).on(getFreekassaDataFx.doneData, (_, res) => res.data)
const $freekassaDataFail = createStore(false)
  .on(getFreekassaDataFx.failData, () => true)
  .reset(getFreekassaDataFx)
const $freekassaDataLoading = getFreekassaDataFx.pending

sample({
  clock: getPaymentData,
  source: {
    username: $paymentUsername,
    server: $serverId,
    amount: $premAmount,
    id: $premId
  },
  target: [getPGSDataFx, getPayPalichDataFx, getEnot3DataFx, getFreekassaDataFx]
})

/** selectors */

export const useEnotData = () => {
  const data = useStore($enotData)
  const fail = useStore($enotDataFail)
  const loading = useStore($enotDataLoading)
  return { data, fail, loading }
}

export const useEnot3Data = () => {
  const data = useStore($enot3Data)
  const fail = useStore($enot3DataFail)
  const loading = useStore($enot3DataLoading)
  return { data, fail, loading }
}

export const usePGSData = () => {
  const data = useStore($pgsData)
  const fail = useStore($pgsDataFail)
  const loading = useStore($pgsDataLoading)
  return { data, fail, loading }
}

export const usePayPalichData = () => {
  const data = useStore($payPalichData)
  const fail = useStore($payPalichDataFail)
  const loading = useStore($payPalichDataLoading)
  return { data, fail, loading }
}

export const useGMPaysData = () => {
  const data = useStore($gmPaysData)
  const fail = useStore($gmPaysDataFail)
  const loading = useStore($gmPaysDataLoading)
  return { data, fail, loading }
}

export const usePSONData = () => {
  const data = useStore($psonData)
  const fail = useStore($psonDataFail)
  const loading = useStore($psonDataLoading)
  return { data, fail, loading }
}

export const useFreekassaData = () => {
  const data = useStore($freekassaData)
  const fail = useStore($freekassaDataFail)
  const loading = useStore($freekassaDataLoading)
  return { data, fail, loading }
}

import { useStep } from './model/step'
import { FormPay } from './ui/form-pay'
import { FormValidate } from './ui/form-validate'

export const PremiumForm = () => {
  const { step } = useStep()

  return (
    <div className="donateform">
      {step === 0 && <FormPay />}
      {step === 1 && <FormValidate />}
    </div>
  )
}

// split number with spaces every 3 digits
export const splitNumber = (number) => {
  const str = number.toString()
  const parts = []
  let i = str.length
  while (i > 0) {
    parts.push(str.substring(i - 3, i))
    i -= 3
  }
  return parts.reverse().join(' ')
}

// get plural form of word by number in Russian
export const getPluralForm = (number, forms) => {
  let form = 2
  if (number % 10 === 1 && number % 100 !== 11) {
    form = 0
  } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    form = 1
  }
  return `${number} ${forms[form]}`
}

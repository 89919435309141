export const IconDiscord = () => {
  return (
    <svg
      className="socials-icon icon-discord"
      width="42"
      height="42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="socials-icon-shape"
        d="M18.5 1.443a5 5 0 0 1 5 0l13.187 7.614a5 5 0 0 1 2.5 4.33v15.226a5 5 0 0 1-2.5 4.33L23.5 40.557a5 5 0 0 1-5 0L5.313 32.943a5 5 0 0 1-2.5-4.33V13.387a5 5 0 0 1 2.5-4.33L18.5 1.443Z"
        fill="#5865f2"
      />
      <path
        d="M27.238 15.137a14.848 14.848 0 0 0-3.664-1.136.056.056 0 0 0-.059.028 10.33 10.33 0 0 0-.456.937 13.705 13.705 0 0 0-4.115 0 9.452 9.452 0 0 0-.464-.937.058.058 0 0 0-.058-.028c-1.286.221-2.516.61-3.664 1.136a.052.052 0 0 0-.024.021c-2.334 3.486-2.973 6.887-2.66 10.245.002.017.011.032.024.042a14.926 14.926 0 0 0 4.495 2.273.058.058 0 0 0 .063-.021c.346-.473.655-.972.92-1.496a.057.057 0 0 0-.032-.08 9.828 9.828 0 0 1-1.404-.668.058.058 0 0 1-.006-.096c.095-.07.189-.144.279-.219a.056.056 0 0 1 .058-.008c2.946 1.345 6.135 1.345 9.046 0a.056.056 0 0 1 .06.008c.09.074.184.148.279.219a.058.058 0 0 1-.005.096 9.228 9.228 0 0 1-1.405.668.057.057 0 0 0-.03.08c.27.524.578 1.022.918 1.495.014.02.04.029.063.021a14.877 14.877 0 0 0 4.502-2.272.058.058 0 0 0 .023-.041c.376-3.883-.628-7.255-2.66-10.245a.046.046 0 0 0-.024-.022Zm-9.223 8.221c-.887 0-1.618-.814-1.618-1.814s.717-1.814 1.618-1.814c.908 0 1.632.821 1.618 1.814 0 1-.717 1.814-1.618 1.814Zm5.981 0c-.887 0-1.618-.814-1.618-1.814s.717-1.814 1.618-1.814c.908 0 1.632.821 1.618 1.814 0 1-.71 1.814-1.618 1.814Z"
        fill="#fff"
      />
    </svg>
  )
}

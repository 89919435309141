export const IconLogotype = () => {
  return (
    <svg width="39" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m38.59 19.688-9.145-9.037c-.106-.104-.106-.104-.185-.104H5.883c-.184 0-.29-.104-.29-.287v-.833c0-.182.106-.286.29-.286h23.483c.105 0 .105 0 .184-.105l7.643-7.552L38.221.47c.184-.183 0-.469-.185-.469H9.705c-.106 0-.106 0-.185.104L.48 9.062c-.105.105-.105.105-.105.183v1.094c0 .104 0 .104.105.182l9.146 8.932c.105.104.105.104.184.104h23.298c.184 0 .29.104.29.287v.729c0 .182-.106.286-.29.286H9.705c-.106 0-.106 0-.185.105L.85 29.53c-.185.183-.106.469.184.469H29.26c.106 0 .106 0 .185-.104l9.04-8.932c.105-.105.105-.105.105-.183v-1.093c.079.078 0 .078 0 0Z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient
          id="a"
          x1="38.625"
          y1="0"
          x2=".777"
          y2="30.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFF0" />
          <stop offset="1" stopColor="#0267FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

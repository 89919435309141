import { useEffect, useState } from 'react'
import Select from 'react-select'
import { usePayForm } from '../../model/pay'
import { selectStyles } from './styles-select'

export const FormPay = () => {
  const {
    setUsername,
    server,
    username,
    isValidating,
    isValidError,
    onValidate,
    isDataValid,
    getServersList,
    serversListOptions,
    serversListLoading,
    setServer,
    premium,
    getPremiumList,
    setPremium,
    premiumListLoading,
    premiumListOptions,
    premiumBenefits
  } = usePayForm()

  useEffect(() => {
    getServersList()
    getPremiumList()
  }, [getPremiumList, getServersList])

  const [closed, setClosed] = useState(false)

  return (
    <div className="donateform__start">
      <div className="input-group">
        <Select
          styles={selectStyles}
          placeholder={serversListLoading ? 'Загружаем сервера...' : 'Выберите сервер'}
          options={serversListOptions}
          value={server && Object.keys(server).length === 0 ? null : server}
          onChange={(item) => setServer(item)}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? 'Ничего не найдено' : 'Ничего не найдено'
          }
        />
        <div className="input-icon icon-server"></div>
      </div>

      {server.freeAddPrem > 1 && (
        <div className="salesbar">
          <p>Сегодня акция, купи премиум и получи бонусом X{server.freeAddPrem} кол-во дней</p>
        </div>
      )}

      <div className="input-group">
        <input
          type="text"
          placeholder="Введите ваш логин"
          className="ordinput"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <div className="input-icon icon-login"></div>
      </div>

      <div className="input-group">
        <Select
          styles={selectStyles}
          placeholder={premiumListLoading ? 'Загружаем пакеты...' : 'Выберите премиум'}
          options={premiumListOptions}
          value={premium && Object.keys(premium).length === 0 ? null : premium}
          onChange={(item) => setPremium(item)}
          noOptionsMessage={({ inputValue }) =>
            !inputValue ? 'Ничего не найдено' : 'Ничего не найдено'
          }
        />
        <div className="input-icon icon-money"></div>
      </div>

      {Boolean(Object.keys(premium).length !== 0) && (
        <div class="input-group final">
          <input
            style={{
              cursor: 'default'
            }}
            type="text"
            class="ordinput pright"
            readOnly
            value={`${premium.value - (premium.value / 100) * premium.discount} ₽`}
          />
          <div class="input-icon icon-sum"></div>
          {!!premium.discount && (
            <div class="postext large">
              <s>{premium.value} ₽</s>
              <span>-{premium.discount}%</span>
            </div>
          )}
        </div>
      )}

      <div
        className={`padvantages ${!closed && 'active'}`}
        onClick={() => setClosed((prev) => !prev)}
      >
        {closed ? 'Показать преимущества' : 'Скрыть преимущества'}
      </div>

      {!premiumListLoading ? (
        <ul className="input-ul" hidden={closed}>
          {premiumBenefits.map((benefit, idx) => {
            return <li key={idx}>{benefit}</li>
          })}
        </ul>
      ) : (
        <ul className="input-ul" hidden={closed} style={{ listStyle: 'none' }}>
          <li>Загружаем информацию</li>
        </ul>
      )}

      <button className="nextbtn" disabled={isValidating} onClick={() => onValidate()}>
        {isValidating ? 'Проверяем данные...' : 'Далее'}
      </button>

      {isValidError && (
        <div className="errorbar">{isValidError && <p>Неверно введен логин</p>}</div>
      )}

      {!isDataValid && <div className="errorbar">{!isDataValid && <p>Заполните все поля</p>}</div>}

      <p className="under">
        Возникили проблемы с пополнением? Напишите на smotramta@gmail.com или в сообщения нашего{' '}
        <a href="https://vk.com/smotrarage" target="_blank" rel="noreferrer">
          сообщества.
        </a>
      </p>
    </div>
  )
}

import Michael from '../../images/Dima.png'
import Michael2 from '../../images/Dima@2x.png'
import Servers from '../Servers/Servers'
import { PremiumForm } from './premium-form'
import { useStep } from './premium-form/model/step'
import './PremiumScreen.scss'

const PremiumScreen = () => {
  const { step } = useStep()

  return (
    <div className="mainsection">
      <img src={Michael} srcSet={`${Michael2} 2x`} alt={'...'} className={'michael'} />

      <div className="mainsection__inner donatescreen container">
        <div className="mainsection__inner--left">
          <img src={Michael} srcSet={`${Michael2} 2x`} alt={'...'} className={'michael-min'} />

          <div className="donatescreen__form">
            <div className="donatescreen__form--title">
              <h1>Купить премиум</h1>

              {step === 0 && (
                <p>
                  Подключая премиум аккаунт, вы получаете массу преимуществ и возможностей на нашем
                  сервере. После приобретения, вы автоматически в течении двух минут получите
                  премиум доступ в игре
                </p>
              )}
              {step === 1 && <p>Пожалуйста, проверьте правильность данных</p>}
            </div>
            <PremiumForm />
          </div>
        </div>

        {/*Список серверов*/}
        <div className="mainsection__inner--right">
          <Servers />
        </div>
      </div>
    </div>
  )
}

export default PremiumScreen

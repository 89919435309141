import React from 'react'
import { hydrate, render } from 'react-dom'
import loadScripts from 'snapshotify'
import App from './App'
import './fonts/Montserrat.css'
import './index.scss'

const rootElement = document.getElementById('root') as HTMLDivElement

if (rootElement.hasChildNodes()) {
  loadScripts().then(() => hydrate(<App />, rootElement))
} else {
  render(<App />, rootElement)
}

import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react'

export const nextStep = createEvent()
export const prevStep = createEvent()

const $step = createStore(0)
  .on(nextStep, () => 1)
  .on(prevStep, () => 0)

export const useStep = () => {
  const step = useStore($step)

  return {
    step,
    nextStep,
    prevStep
  }
}

import { useEffect } from 'react'
import { getPluralForm } from '../../lib'
import {
  getPaymentData,
  useEnot3Data,
  useFreekassaData,
  usePGSData,
  usePayPalichData
} from '../../model/donate'
import { usePayForm } from '../../model/pay'
import { useStep } from '../../model/step'

export const FormValidate = () => {
  const { prevStep } = useStep()
  const { username, server, premiumAmount, premiumId } = usePayForm()

  const { data: enot3Data, loading: enot3Loading, fail: enot3Fail } = useEnot3Data()
  const { data: pgsData, loading: pgsLoading, fail: pgsFail } = usePGSData()
  const { data: payPalichData, loading: payPalichLoading, fail: payPalichFail } = usePayPalichData()
  const { data: freekassaData, loading: freekassaLoading, fail: freekassaFail } = useFreekassaData()

  useEffect(() => {
    getPaymentData()
  }, [])

  return (
    <div className="donateform__finish">
      <div className="donateform__finish--inner">
        <div className="finish-item">
          <div className="top">Сервер:</div>
          <div className="bottom">
            {server.label}
            <span className="icon-server"></span>
          </div>
        </div>

        <div className="finish-item">
          <div className="top">Логин:</div>
          <div className="bottom">
            {username}
            <span className="icon-login"></span>
          </div>
        </div>

        <div className="finish-item">
          <div className="top">Длительность:</div>
          <div className="bottom">
            {server.freeAddPrem > 1 ? (
              <div>
                <strike
                  style={{
                    marginRight: 5
                  }}
                >
                  {getPluralForm(parseInt(premiumId), ['день', 'дня', 'дней'])}
                </strike>

                {getPluralForm(Math.ceil(parseInt(premiumId) * server.freeAddPrem), [
                  'день',
                  'дня',
                  'дней'
                ])}
              </div>
            ) : (
              <div>{getPluralForm(parseInt(premiumId), ['день', 'дня', 'дней'])}</div>
            )}
            <span className="icon-money"></span>
          </div>
        </div>

        <div className="finish-item">
          <div className="top">К оплате:</div>
          <div className="bottom">
            {premiumAmount} ₽<span className="icon-money"></span>
          </div>
        </div>
      </div>

      {/* PGS Button */}
      <div>
        <form>
          {pgsLoading ? (
            <button className="paybutton cblue loading">Загружаем PGS</button>
          ) : (
            <a className="paybutton cblue" href={pgsData ? pgsData.paylink : ''}>
              Карты мира, скины, крипта <span>(PGS)</span>
            </a>
          )}
        </form>
      </div>

      {/* Freekasa Button */}
      <div>
        <form>
          {freekassaLoading ? (
            <button className="paybutton cred loading">Загружаем Freekassa</button>
          ) : (
            <a className="paybutton cred" href={freekassaData ? freekassaData.url : ''}>
              Карты РФ, скины, крипта <span>(Freekassa)</span>
            </a>
          )}
        </form>
      </div>

      {/* Enot3 Button */}
      <div>
        <form>
          {enot3Loading ? (
            <button className="paybutton cyellow loading">Загружаем P2P</button>
          ) : (
            <a className="paybutton cyellow" href={enot3Data ? enot3Data.url : ''}>
              Картой СБЕР, Тинькофф <span>(P2P)</span>
            </a>
          )}
        </form>
      </div>

      {/* PayPalich Button */}
      <div>
        <form>
          {payPalichLoading ? (
            <button className="paybutton corange loading">Загружаем PayPalich</button>
          ) : (
            <a className="paybutton corange" href={payPalichData ? payPalichData.url : ''}>
              Карты РФ и др. <span>(PayPalich)</span>
            </a>
          )}
        </form>
      </div>

      {pgsFail && (
        <div className="errorbar">
          <p>Ошибка запроса к PGS</p>
        </div>
      )}

      {freekassaFail && (
        <div className="errorbar">
          <p>Ошибка запроса к Freekassa</p>
        </div>
      )}

      {payPalichFail && (
        <div className="errorbar">
          <p>Ошибка запроса к PayPalich</p>
        </div>
      )}

      {enot3Fail && (
        <div className="errorbar">
          <p>Ошибка запроса к P2P</p>
        </div>
      )}

      <p className="acceptpolicy">Я делаю добровольное пожертвование</p>
      <span className="prevbtn" onClick={() => prevStep()}>
        Назад
      </span>
    </div>
  )
}

import { useRef } from 'react'
import './CardsContainer.scss'

export const CardsContainer = () => {
  const inputEl = useRef(null)
  const handleCopy = (e) => {
    e.preventDefault()
    inputEl.current.select()
    document.execCommand('copy')
    alert(`IP: ${inputEl.current.value} скопирован.`)
  }

  return (
    <div className="cards-grid">
      {/*Шаг 1*/}
      <div className="card">
        <div className="card__face card__face--front">
          <span className="step">Шаг 1</span>
          <div className="card-title">Купите и установите лицензионную GTA 5</div>
          <p className="card-p">
            Для игры на проекте подходят: версия в <strong>Rockstar Games Launcher</strong>, либо{' '}
            <strong>Steam</strong> версия, либо <strong>Epic Games</strong> версия.
          </p>
          <p className="card-p">
            Лицензионную GTA 5 можно купить в Steam, Epic Games или на других площадках цифровой
            дистрибуции.
          </p>
        </div>

        <div className="card__face card__face--reverse">
          <span className="step">Шаг 1</span>
          <div className="card-title">Купите и установите лицензионную GTA 5</div>
          <a
            href="https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/?l=russian"
            target={'_blank'}
            rel={'noreferrer'}
            className="card-btn steam"
          >
            Купить в Steam
          </a>
          <a
            href="https://www.rockstargames.com/ru/games/V"
            target={'_blank'}
            rel={'noreferrer'}
            className="card-btn rockstar"
          >
            Купить в Rockstar
          </a>
          <a
            href="https://www.epicgames.com/store/ru/product/grand-theft-auto-v/home"
            target={'_blank'}
            rel={'noreferrer'}
            className="card-btn epic"
          >
            Купить в Epic Games
          </a>
        </div>
      </div>

      {/*Шаг 2*/}
      <div className="card">
        <div className="card__face card__face--front">
          <span className="step">Шаг 2</span>
          <div className="card-title">Установите Rage Multiplayer</div>
          <p className="card-p">
            Далее <strong>cкачайте официальный лаунчер RAGE:MP</strong>.
          </p>
          <p className="card-p">
            По завершению скачивания, <strong>установите лаунчер по любому свободному пути</strong>,
            не содержащему файлы вашей игры или кириллицы в названии.
          </p>
        </div>

        <div className="card__face card__face--reverse">
          <span className="step">Шаг 2</span>
          <div className="card-title">Установите Rage Multiplayer</div>
          <a
            href="https://cdn.rage.mp/public/files/RAGEMultiplayer_Setup.exe"
            target={'_blank'}
            rel={'noreferrer'}
            className="card-btn rage"
          >
            Скачать Rage Multiplayer
          </a>
        </div>
      </div>

      {/*Шаг 3*/}
      <div className="card">
        <div className="card__face card__face--front">
          <span className="step">Шаг 3</span>
          <div className="card-title">Подключитесь к серверу</div>
          <p className="card-p">
            После установки <strong>запустите RAGE Multiplayer</strong>, далее нажмите сверху окна{' '}
            <strong>кнопку Direct Connect</strong>.
          </p>
          <p className="card-p">
            В открывшемся окне введите{' '}
            <strong>IP адрес нашего сервера, после нажмите Connect</strong>
          </p>
        </div>

        <div className="card__face card__face--reverse">
          <span className="step">Шаг 3</span>
          <div className="card-title">Подключитесь к серверу</div>
          <input
            type="text"
            name="copyip"
            id="copyip"
            value={'r1.smotra.games:22005'}
            ref={inputEl}
            readOnly
          />
          <a className="card-btn" href={'#!'} onClick={handleCopy}>
            Скопировать
          </a>
        </div>
      </div>

      {/*Оранжевая карточка*/}
      <div className="card-box">
        <div className="card-title">Наслаждайтесь игрой на SMOТRArage!</div>
        <p className="card-p">Ресурсы сервера скачаются автоматически, и игра запустится!</p>
        <p className="card-p">Желаем Вам приятной игры на нашем проекте!</p>
      </div>
    </div>
  )
}

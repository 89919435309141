import { MainSection } from '../components/MainSection/MainSection'
import AboutSection from '../components/AboutSection/AboutSection'
import { StartSection } from '../components/StartSection/StartSection'
import { SocialSection } from '../components/SocialSection/SocialSection'

const Home = () => {
  return (
    <>
      <MainSection />
      <AboutSection />
      <StartSection />
      <SocialSection />
    </>
  )
}

export default Home

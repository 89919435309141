import axios from 'axios'
import { combine, createEffect, createEvent, createStore, guard, sample } from 'effector'
import { useStore } from 'effector-react'
import { nextStep } from './step'

const getServersList = createEvent()
const getPremiumList = createEvent()

const setUsername = createEvent()

const onValidate = createEvent()
const setDataInvalid = createEvent()

const setServer = createEvent()

const setPremium = createEvent()

const getPremiumListFx = createEffect(() => {
  return axios.get('https://smotrarage.ru/api/premium.php')
})

const getServersListFx = createEffect(() => {
  return axios.get('https://smotrarage.ru/api/srvs.php')
})

const validateUserFX = createEffect(({ username }) => {
  return axios.get(`https://smotrarage.ru/api/checklog.php?login=${username}`)
})

const $serversList = createStore([]).on(getServersListFx.doneData, (_, data) => data.data)
const $serversListLoading = getServersListFx.pending
const $serversListOptions = $serversList.map((list) => {
  return list.map((item) => {
    return {
      label: item.name,
      value: item.uniqID,
      freeAddPrem: item.freeAddPrem
    }
  })
})

sample({
  clock: getServersList,
  target: getServersListFx
})

const $premiumList = createStore([]).on(getPremiumListFx.doneData, (_, data) => data.data.variants)
const $premiumBenefits = createStore([]).on(
  getPremiumListFx.doneData,
  (_, data) => data.data.benefits
)
const $premiumListLoading = getPremiumListFx.pending
const $premiumListOptions = $premiumList.map((list) => {
  return list.map((item) => {
    return {
      label: item.label,
      id: item.id,
      value: item.val,
      discount: item.discount
    }
  })
})

sample({
  clock: getPremiumList,
  target: getPremiumListFx
})

export const $server = createStore({})

sample({
  clock: setServer,
  target: $server
})

export const $premium = createStore({})

export const $premiumAmount = $premium.map((item) => {
  // prive is item.value, discount is item.discount
  return item.value - (item.value * item.discount) / 100
})
export const $premiumId = $premium.map((item) => item.id)

sample({
  clock: setPremium,
  target: $premium
})

export const $username = createStore('').on(setUsername, (_, username) => username)

const $isValid = createStore(false)
  .on(validateUserFX.doneData, (_, isValid) => isValid.data === 'Success')
  .reset([setUsername, onValidate])

const $isValidError = createStore(false)
  .on(validateUserFX.doneData, (_, isValid) => isValid.data !== 'Success')
  .reset([setUsername, onValidate])

const $isValidating = validateUserFX.pending

const $isDataValid = createStore(true)
  .on(setDataInvalid, () => false)
  .reset([setUsername, setPremium, setServer])

const $payData = combine({
  server: $server,
  username: $username,
  currentServer: $server,
  premium: $premium
})

guard({
  clock: onValidate,
  source: $payData,
  filter: ({ username, currentServer, premium }) =>
    username.length > 0 && currentServer.value > 0 && !!premium.id,
  target: validateUserFX
})

guard({
  clock: onValidate,
  source: $payData,
  filter: ({ username, currentServer, premium }) =>
    username.length <= 0 || !currentServer.value || !premium.id,
  target: setDataInvalid
})

sample({
  clock: validateUserFX.doneData,
  filter: (isValid) => isValid.data === 'Success',
  target: nextStep
})

// selectors

export const usePayForm = () => {
  const serversListOptions = useStore($serversListOptions)
  const serversListLoading = useStore($serversListLoading)

  const premiumBenefits = useStore($premiumBenefits)
  const premiumListOptions = useStore($premiumListOptions)
  const premiumListLoading = useStore($premiumListLoading)

  const server = useStore($server)
  const premium = useStore($premium)

  const username = useStore($username)
  const isValid = useStore($isValid)
  const isValidError = useStore($isValidError)
  const isValidating = useStore($isValidating)
  const isDataValid = useStore($isDataValid)
  const premiumAmount = useStore($premiumAmount)
  const premiumId = useStore($premiumId)

  return {
    server,
    username,
    setUsername,
    isDataValid,
    onValidate,
    isValid,
    isValidating,
    isValidError,
    setServer,
    getServersList,
    serversListOptions,
    serversListLoading,
    getPremiumList,
    setPremium,
    premiumListLoading,
    premiumListOptions,
    premium,
    premiumBenefits,
    premiumAmount,
    premiumId
  }
}

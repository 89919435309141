export const IconVK = () => {
  return (
    <svg
      className="socials-icon icon-vk"
      width="42"
      height="42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="socials-icon-shape"
        d="M18.5 1.443a5 5 0 0 1 5 0l13.187 7.614a5 5 0 0 1 2.5 4.33v15.226a5 5 0 0 1-2.5 4.33L23.5 40.557a5 5 0 0 1-5 0L5.313 32.943a5 5 0 0 1-2.5-4.33V13.387a5 5 0 0 1 2.5-4.33L18.5 1.443Z"
        fill="#0077ff"
      />
      <path
        d="M21.804 27c-6.15 0-9.658-4.13-9.804-11h3.08c.102 5.043 2.373 7.18 4.172 7.62V16h2.9v4.35c1.777-.188 3.643-2.17 4.273-4.35h2.9c-.483 2.687-2.507 4.669-3.946 5.483 1.44.661 3.744 2.39 4.621 5.517h-3.193c-.686-2.092-2.395-3.71-4.654-3.93V27h-.35Z"
        fill="#fff"
      />
    </svg>
  )
}
